@import url('https://fonts.googleapis.com/css?family=Josefin+Sans:300,400,600,700|Prompt:300,400,500,600,700&display=swap&subset=thai');
body {
  margin: 0;
  font-family: 'Josefin Sans', 'Prompt', sans-serif;
  background-color: #1b1e21;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lock-screen {
  overflow: hidden;
}
